.project {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .project .projectTitle {
    font-family: Homemade Apple,cursive;
    font-size: 60px;
  }
  
  .projectList {
    width: 70vw;
    height: auto;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    place-items: center;
  }
  
  /* project ITEM STYLING */
  
  .projectItem {
    border-radius: 15px;
    width: 300px;
    height: 350px;
    margin: 20px;
    box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.2);
  }
  .projectItem:hover {
    box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.5);
    transition: 0.3s ease-in;
    cursor: pointer;
  }
  
  .projectItem div {
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    width: 100%;
    height: 200px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  
  .projectItem h1,
  .projectItem p {
    margin-left: 20px;
  }
  
  @media only screen and (max-width: 1300px) {
    .projectList {
      grid-template-columns: 1fr 1fr;
    }
  }
  
  @media only screen and (max-width: 800px) {
    .projectList {
      grid-template-columns: 1fr;
    }
  }